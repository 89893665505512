if (!window.mobileMenuLoaded) {
  const hamburgerMobileDropdownBtn = document.getElementById('hamburger-mobile-dropdown-btn');

  if (hamburgerMobileDropdownBtn) {
    hamburgerMobileDropdownBtn.addEventListener('click', () => {
      const menu = document.getElementById('hamburger-mobile-menu');
      menu.toggleAttribute('data-expanded');
      menu.classList.toggle('hamburger-mobile-menu-container-expanded');
    });
  }

  const hamburgerMobileMenuLinks = [
    'for-companies',
    'for-industries',
    'for-restaurants',
    'about-us',
    'contact-us',
  ];

  hamburgerMobileMenuLinks.forEach(menuLink => {
    const menuLinkBtn = document.getElementById(`${menuLink}-mobile-dropdown-btn`);

    if (menuLinkBtn) {
      menuLinkBtn.addEventListener('click', () => {
        const submenu = document.getElementById(`${menuLink}-mobile-submenu`);

        submenu.classList.toggle('submenu-expanded');
      });
    }
  });
}

window.mobileMenuLoaded = true;
